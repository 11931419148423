import React, { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { isFooterActive as isFooterActiveSelector } from 'services/app';
import { isDesktopLayout } from 'services/device';
import HeaderNotification from '../../objects/HeaderNotification';
import { getLandingPageContent } from 'services/admin';
import Footer from 'components/footer/Footer';
import { shouldRenderChannelGate } from 'services/gate';
import { isEmbed as isEmbedSelector, isFullscreenActive, isTheaterActive as isTheaterActiveSelector, isTheaterEmbed as isTheaterEmbedSelector, isVideoEmbed as isVideoEmbedSelector } from 'services/user-layout/selectors';
import { Container, StyledGate, StyledHeader } from './styles';

/**
 * ContentLayout holds the header, gate and footer.
 * Its used as wrapper for landing page, video page and channel page.
 */
const ContentLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const isDesktop = useSelector(isDesktopLayout);
  const isTheaterActive = useSelector(isTheaterActiveSelector);
  const isTheaterEmbed = useSelector(isTheaterEmbedSelector);
  const isChannelGateOn = useSelector(shouldRenderChannelGate);
  const isEmbed = useSelector(isEmbedSelector);
  const isVideoEmbed = useSelector(isVideoEmbedSelector);
  const fullscreenActive = useSelector(isFullscreenActive);
  const isFooterActive = useSelector(isFooterActiveSelector);

  const showFooter = React.useMemo(() => {
    return (
      isDesktop &&
      !isEmbed &&
      !isTheaterActive &&
      !isTheaterEmbed &&
      !isVideoEmbed &&
      !fullscreenActive &&
      !isChannelGateOn
    );
  }, [isDesktop, isEmbed, isTheaterActive, isTheaterEmbed, isVideoEmbed, fullscreenActive, isChannelGateOn]);

  const render = () => {
    if (isChannelGateOn) {
      return null;
    }

    return <>{children}</>;
  };

  return (
    <Container>
      {
        isDesktop && (
          <>
            <HeaderNotification />
            <StyledHeader />
          </>
        )
      }
      <StyledGate />
      {render()}
      {showFooter && isFooterActive && <Footer />}
    </Container>
  );
};

export default ContentLayout;
