import Image from 'components/ui/Image';
import React, { useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { useSelector } from 'react-redux';
import hash from 'json-stable-stringify';
import { inAccessMenu, getActiveAction, getHasAdminModal } from 'services/admin/selectors';
import {
  getSavedSubscriptionGateBackgroundWebImage,
  getSavedSubscriptionGateBackgroundMobileImage,
  getBackgroundImage,
} from 'services/app/selectors';
import {
  isOverlayEmbed as isOverlayEmbedAction,
  isVideoEmbed as isVideoEmbedAction,
} from 'services/user-layout/selectors';
import { getFixedGateData, shouldRenderChannelGate } from 'services/gate/selectors';
import defaultBackground from 'assets/access_control_default_bg.jpg';
import { mobileLandscapeOnly } from 'style/mixins';
import { isMobileLayout } from 'services/device/selectors';
import { GateBackgroundEnum, IAccessGateBackground } from 'models/IGate';
import { ActionKey } from 'services/admin/constants';
import { useAdminTranslation } from 'hooks/use-translation';
import { getIsOrientationLocked } from 'services/user-layout/selectors/common';

interface IBackgroundProps {
  channelLoaded?: boolean;
  className?: string;
}

function getBackground(
  background?: IAccessGateBackground,
  legacyDesktopBackground?: string,
  legacyMobileBackground?: string,
) {
  if (!background) {
    return {
      desktopImage: legacyDesktopBackground,
      mobileImage: legacyMobileBackground,
    };
  }

  if (background?.type === GateBackgroundEnum.color) {
    return null;
  }

  const desktopImage = background?.desktopImage ||
    legacyDesktopBackground ||
    defaultBackground;
  const mobileImage = background?.mobileImage ||
    legacyMobileBackground ||
    defaultBackground;
  return { desktopImage, mobileImage };
}

export default function Background({
  channelLoaded = true,
  className,
}: IBackgroundProps) {
  const { t } = useAdminTranslation();
  const blur = useSelector(getHasAdminModal);
  const backgroundImage = useSelector(getBackgroundImage);
  const isMobile = useSelector(isMobileLayout);
  const isAccess = useSelector(inAccessMenu);
  const activeAction = useSelector(getActiveAction);
  const isOverlayEmbed = useSelector(isOverlayEmbedAction);
  const isVideoEmbed = useSelector(isVideoEmbedAction);
  const isChannelGateOn = useSelector(shouldRenderChannelGate);
  const isOrientationLocked = useSelector(getIsOrientationLocked);
  const subscriptionGateBackgroundMobileImage =
    useSelector(getSavedSubscriptionGateBackgroundMobileImage);
  const subscriptionGateBackgroundWebImage =
    useSelector(getSavedSubscriptionGateBackgroundWebImage);
  const { background: gateBackground } = useSelector(getFixedGateData(t));

  const imageBackground = getBackground(
    gateBackground,
    subscriptionGateBackgroundWebImage,
    subscriptionGateBackgroundMobileImage,
  );

  const [images, setImages] = useState({
    activeBackground: '' as string | undefined,
    activeOldBackground: '' as string | undefined,
    currentChannelBackgroundImage: backgroundImage,
    currentSubscriptionBackgroundMobileImage: imageBackground?.mobileImage as string | undefined,
    currentSubscriptionBackgroundWebImage: imageBackground?.desktopImage as string | undefined,
  });

  useEffect(() => {
    const desktopBackground = !isMobile && isChannelGateOn ?
      imageBackground?.desktopImage : undefined;
    const mobileBackground = isMobile && isChannelGateOn ?
      imageBackground?.mobileImage : undefined;
    const channelBackground = !isChannelGateOn && backgroundImage ? backgroundImage : undefined;
    const activeBackground = desktopBackground || mobileBackground || channelBackground;

    const oldDesktopBackground = !isMobile && isChannelGateOn ?
      images.currentSubscriptionBackgroundWebImage : undefined;
    const oldMobileBackground = isMobile && isChannelGateOn ?
      images.currentSubscriptionBackgroundMobileImage : undefined;
    const oldChannelBackground = !isChannelGateOn && images.currentChannelBackgroundImage ?
      images.currentChannelBackgroundImage : undefined;
    const activeOldBackground = oldDesktopBackground || oldMobileBackground || oldChannelBackground;

    setImages({
      activeBackground,
      activeOldBackground,
      currentChannelBackgroundImage: backgroundImage,
      currentSubscriptionBackgroundMobileImage: mobileBackground,
      currentSubscriptionBackgroundWebImage: desktopBackground,
    });
  }, [
    backgroundImage,
    subscriptionGateBackgroundWebImage,
    subscriptionGateBackgroundMobileImage,
    isMobile,
    isChannelGateOn,
    hash(gateBackground),
  ]);

  // dont display background if embed=overlays
  const displayBackground = images.activeBackground && !isOverlayEmbed;
  const displayOldBackground = images.activeBackground && images.activeOldBackground && !isOverlayEmbed;
  const hideBackground = isVideoEmbed || isAccess || (
    activeAction === ActionKey.earnings
  );

  return !hideBackground ? (
    <>
      {
        displayBackground && (
          <CurrentBackground
            className={className}
            key={images.activeBackground}
            blur={blur}
            loaded={channelLoaded}
            isOrientationLocked={isOrientationLocked}
            src={images.activeBackground}
          />
        )
      }
      {
        displayOldBackground && images.activeOldBackground !== images.activeBackground ? (
          <OldBackground
            key={images.activeOldBackground}
            src={images.activeOldBackground}
          />
        ) :
          null
      }
    </>
  ) : null;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const FADE_SPEED = 300;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

type ICurrentBackgroundProps = React.ComponentProps<typeof Image> & {
  blur: boolean;
  isOrientationLocked: boolean;
  loaded: boolean;
};

const CurrentBackground = styled(({ loaded, ...otherProps }: ICurrentBackgroundProps) => <Image {...otherProps} />)`
  object-fit: cover;
  margin: 0 !important;
  opacity: 0;
  ${props => props.loaded ? css`animation: ${fadeIn} ${FADE_SPEED}ms linear` : ''};
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  ${({ isOrientationLocked }) => isOrientationLocked && mobileLandscapeOnly`
    width: 100vh;
    height: 100vw;
  `}
  ${({ blur }) => blur && 'filter: blur(10px);'}
`;

const OldBackground = styled(Image)`
  position: absolute;
  animation: ${fadeOut} ${FADE_SPEED}ms linear;
  animation-fill-mode: forwards;
  max-width: 100%;
`;
