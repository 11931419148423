import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import React from 'react';
import { useSelector } from 'react-redux';
import { isEditMode } from 'services/admin';

const EditablePlugin = () => {
  const [editor] = useLexicalComposerContext();
  const isEditing = useSelector(isEditMode);

  React.useEffect(() => {
    if (editor.isEditable() !== isEditing) {
      editor.setEditable(isEditing);
    }
  }, [editor, isEditing]);

  return null;
};

export default EditablePlugin;
