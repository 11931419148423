import styled from 'styled-components';
import { ADMIN_ACCENT_PRIMARY, ADMIN_ALERT_CONFIRMATION, ADMIN_ALERT_ERROR, ADMIN_HIGHLIGHT_PRIMARY, ADMIN_SURFACE_2, ADMIN_SURFACE_4, ADMIN_TEXT_100, ADMIN_TEXT_300 } from 'style/constants';
import TextInput from 'components/admin2/TextInput';
import { css } from 'styled-components';
import Icon from 'components/ui/Icon';
import { ADMIN_TEXT_LABEL_S_MEDIUM } from 'style/design-system/textStyles';

const COLOR_MAP = css<{
  linkUrlStatus: 'typing' | 'valid' | 'invalid';
  opacity: number;
  value: string;
}>`
  color: ${props => {
    if (props.linkUrlStatus === 'invalid') {
      return ADMIN_ALERT_ERROR(props);
    }
    if (props.linkUrlStatus === 'valid') {
      return ADMIN_ALERT_CONFIRMATION(props);
    }
    if (props.linkUrlStatus === 'typing' && props.value) {
      return ADMIN_TEXT_100(props);
    }
    return ADMIN_TEXT_300(props);
  }};
`;

export const LinkIcon = styled(Icon).attrs({
  name: 'linkOutline',
})`
  display: flex;
  width: 33px;
  height: 100%;
  justify-content: center;

  & svg {
    width: 18px;
    height: 18px;
  }
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const LinkMenu = styled.form`
  position: relative;
  background-color: ${ADMIN_SURFACE_4};
  padding: 7.5px 8px 7.5px 8px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 4px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  background-color: ${ADMIN_SURFACE_2};
  padding: 0px 10px 0px 6px;
  width: 278px;
`;

export const Input = styled(TextInput).attrs({
  opacity: 0.3,
})`
  flex: 1;
  & input {
    ${COLOR_MAP}
    background-color: transparent;
    padding-left: 0;
    ${ADMIN_TEXT_LABEL_S_MEDIUM}

    &::placeholder {
      color: ${ADMIN_TEXT_300};
      ${ADMIN_TEXT_LABEL_S_MEDIUM}
    }
    &::-webkit-input-placeholder { /* WebKit browsers */
      color:  ${ADMIN_TEXT_300};
    }
    &::-moz-placeholder { /* Mozilla Firefox 19+ */
      color:  ${ADMIN_TEXT_300};
      opacity: 1; /* Fix for Firefox */
    }
  }
`;

export const HttpPrefix = styled.span.attrs({
  opacity: 0.3,
})`
  ${COLOR_MAP}
  background-color: transparent;
  ${ADMIN_TEXT_LABEL_S_MEDIUM}
  line-height: normal;
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 6px;
  gap: 4px;
`;

export const RemoveIcon = styled(Icon).attrs({
  name: 'removeIconOutline',
})`
  cursor: pointer;
  & svg {
    width: 13px;
    height: 13px;

    & path {
      fill: ${ADMIN_HIGHLIGHT_PRIMARY};
    }
  }

  &:hover {
    & svg {
      & path, & circle {
        fill: ${ADMIN_ACCENT_PRIMARY};
      }
    }
  }
`;

export const CheckIcon = styled(Icon).attrs({
  name: 'circledCheckmark',
})`
  & svg {
    width: 13px;
    height: 13px;

    & circle {
      fill: ${ADMIN_HIGHLIGHT_PRIMARY};
    }
  }

  &:hover {
    & svg {
      & circle {
        fill: ${ADMIN_ACCENT_PRIMARY};
      }
    }
  }
`;
