import React from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import useUpdateListener from 'components/ui/RichTextEditorV3/use-update-listener';
import useDebounceWithFunction from 'hooks/use-debounce-with-function';
import { $getSelection, $isRangeSelection, RangeSelection } from 'lexical';
import { $getNearestNodeOfType } from '@lexical/utils';
import ExtendedLinkNode from 'components/ui/RichTextEditorV3/nodes/ExtendedLinkNode';
import { removeHttpsFromUrl } from 'utils';

type UseLinkListenersParams = {
  linkColor: string;
  linkUrl: string;
  onChangeLinkColor: (color: string) => void;
  onChangeLinkUrl: (url: string) => void;
};

const useLinkListeners = ({
  linkColor,
  linkUrl,
  onChangeLinkColor,
  onChangeLinkUrl,
}: UseLinkListenersParams) => {
  const [selection, setSelection] = React.useState<RangeSelection | null>(null);
  const [editor] = useLexicalComposerContext();

  useDebounceWithFunction(() => {
    editor.update(() => {
      if (!$isRangeSelection(selection)) {
        return;
      }

      const node = $getNearestNodeOfType(selection.anchor.getNode(), ExtendedLinkNode);
      if (!node) {
        return;
      }

      if (node.getLinkColor() !== linkColor) {
        node.setLinkColor(linkColor);
      }
    });
  }, linkColor, 500);

  const update = () => {
    const innerSelection = $getSelection();
    if (!$isRangeSelection(innerSelection)) {
      return;
    }
    setSelection(innerSelection);

    const node = $getNearestNodeOfType(innerSelection.anchor.getNode(), ExtendedLinkNode);
    if (!node) {
      return;
    }

    if (node.getURL() !== linkUrl) {
      onChangeLinkUrl(removeHttpsFromUrl(node.getURL()));
    }

    if (node.getLinkColor() !== linkColor) {
      onChangeLinkColor(node.getLinkColor());
    }
  };

  useUpdateListener(editor, update);

  return selection;
};

export default useLinkListeners;
